import React, {useEffect, useRef, useState} from 'react';
import {getModelInstance, getModelInstances} from "../../utils/store/selectors";
import {connect} from "react-redux";
import Article from "../../utils/models/Article";
import ArticleDetail from "./ArticleDetail";
import ModelManager from "../../utils/manager/ModelManager";
import Distributor from "../../utils/models/Distributor";
import {ContentType} from "../../utils/enums/ContentType";
import Survey from "../../utils/models/Survey";
import Comment from "../../utils/models/Comment";
import {SurveyType} from "../../utils/enums/SurveyType";
import AUTHSTORAGE from "../../utils/auth/AuthStorage";
import {addModelInstanceDispatch, deleteModelInstanceDispatch, updateModelInstanceDispatch} from "../../utils/store/actions";
import User from "../../utils/models/User";
import FirebaseStorageManager from "../../utils/manager/manager/FirebaseStorageManager";
import EmptyState from "../../components/base/EmptyState";
import {useHistory,withRouter} from "react-router-dom";
import {FireDelete, FireTimestamp} from "../../api/FirebaseService";
import SCROLLHELPER from "../../utils/helper/ScrollHelper";
import HIGHLIGHTEDARTICLES from "../../utils/articles/HighlightedArticles";
import AuthStorage from "../../utils/auth/AuthStorage";
import FAVORITEDARTICLES from "../../utils/articles/FavoritedArticles";

/**
 *
 * @param {string} id
 * @param {Article} article
 * @param {Survey[]} surveys
 * @param {Distributor} group
 * @param {Function} addModelInstance
 * @param {User} authUser
 * @returns {JSX.Element}
 * @constructor
 */
const ArticleDetailContainer = ({id, article, comments, surveys,  authUser, group, addModelInstanceDispatch, updateModelInstanceDispatch}) => {
    const [noContent, setNoContent] = useState(false);
    const [rebuild, setRebuild] = useState(false);
    const [loadedOnce, setLoadedOnce] = useState(false);

    const fixedScrollPosition = useRef(false);
    const deleting = useRef(false);
    const commentsLoading = useRef(false);
    const history = useHistory();
    const hasMoreComments = useRef(true);
    const commentBatchCount = 10;
    const relevantComments = comments.filter(comment => comment.aId === id).sort((a, b) => a.cAt - b.cAt);

    useEffect(() => {
        if(!article || !loadedOnce) {
            try {
                ModelManager.get({model: Article, id: id}).then(article => {
                    setNoContent(!article);
                    setLoadedOnce(true);
                    if(!!article && !article.dAt) {
                        loadSurveysIfNeeded(article);
                    }
                }).catch(() => {
                    setNoContent(true);
                    setLoadedOnce(true);
                });
            } catch (ex) {
                setNoContent(true);
                setLoadedOnce(true);
            }
        } else if(!article.dAt) {
            loadSurveysIfNeeded();
        }

        const relevantComments = comments.filter(comment => comment.aId === id).sort((a, b) => a.cAt - b.cAt);
        if(relevantComments.length < commentBatchCount) {
            loadMoreComments();
        }
    }, [article]);

    if (noContent) {
        return <EmptyState/>
    }

    const loadMoreComments = (lastCreatedAt) => {
        if(!commentsLoading.current && hasMoreComments.current) {
            commentsLoading.current = true;
            if(!fixedScrollPosition.current) {
                fixedScrollPosition.current = true;

                SCROLLHELPER.scrollToTop();
            }

            if(!!lastCreatedAt) {
                ModelManager.list({model: Comment, limit: commentBatchCount, filter: [['aId', '==', id]], orderBy: 'cAt', startAfter: lastCreatedAt}).then(result => {
                    commentsLoading.current = false;
                    hasMoreComments.current = result.length >= commentBatchCount;
                    setRebuild(!rebuild);
                });
            } else {
                ModelManager.list({model: Comment, limit: commentBatchCount, filter: [['aId', '==', id]], orderBy: 'cAt'}).then(result => {
                    commentsLoading.current = false;
                    hasMoreComments.current = result.length >= commentBatchCount;
                    setRebuild(!rebuild);
                });
            }
        }
    };

    const loadSurveysIfNeeded = (loadedArticle) => {
        const loadArticle = article ?? loadedArticle;
        if(!!loadArticle) {
            loadArticle.content.forEach(con => {
                // reload survey to update them
                if(con.type === ContentType.SURVEY) {
                    ModelManager.get({model: Survey, id: con.surveyId, stopEvents: true}).then(async (survey) => {
                        if(survey.type === SurveyType.FREEANSWER) {
                            const authUserAnswer = await ModelManager.get({id: AUTHSTORAGE.getUserId(), resource: "/client/" + AUTHSTORAGE.getClientId() + "/survey/" + con.surveyId + "/answer", stopEvents: true});
                            if(!!authUserAnswer) {
                                survey.authUserAnswer = authUserAnswer['text'];
                            }
                        } else {
                            const authUserVotes = await ModelManager.get({id: AUTHSTORAGE.getUserId(), resource: "/client/" + AUTHSTORAGE.getClientId() + "/survey/" + con.surveyId + "/vote", stopEvents: true});
                            if(!!authUserVotes) {
                                survey.authUserVotes = authUserVotes['votes'];
                            }
                        }

                        addModelInstanceDispatch(Survey, survey);
                    });
                }
            });
        }
    };

    const toggleArticleHighlight = async () => {
        let oldHighlighted = HIGHLIGHTEDARTICLES.isHighlighted(article.id);

        let highlightData = {};

        if (!HIGHLIGHTEDARTICLES.canHighlightMoreArticles() && !oldHighlighted) {
            let oldest = HIGHLIGHTEDARTICLES.getOldestHighlightedArticle()
            HIGHLIGHTEDARTICLES.toggleArticleHighlighted(oldest);
            highlightData[oldest] = FireDelete();
        }

        HIGHLIGHTEDARTICLES.toggleArticleHighlighted(article.id);

        if(!oldHighlighted) {
            highlightData[article.id] = FireTimestamp();
        } else {
            highlightData[article.id] = FireDelete();
        }

        ModelManager.update({useSet: true, stopEvents: true, resource: "/client/" + AuthStorage.getClientId() + "/highlightedArticles/", id: '_all', customData: highlightData});
        updateModelInstanceDispatch(Article, article);
    };

    const toggleArticleFavorited = async () => {
        let oldFavorited = FAVORITEDARTICLES.isFavorited(article.id);

        let favoriteData = {};

        FAVORITEDARTICLES.toggleArticleFavorited(article.id);

        if(!oldFavorited) {
            favoriteData[article.id] = FireTimestamp();
        } else {
            favoriteData[article.id] = FireDelete();
        }

        ModelManager.update({useSet: true, stopEvents: true, resource: `/client/${AuthStorage.getClientId()}/user/${AuthStorage.getUserId()}/article_favorites/`, id: '_all', customData: favoriteData});
        updateModelInstanceDispatch(Article, article);
    };

    const deleteArticle = async () => {
        if(!deleting.current) {
            deleting.current = true;
            const firebaseStorageManager = new FirebaseStorageManager();
            let deleteFutures = [];

            if (HIGHLIGHTEDARTICLES.isHighlighted(article.id)) {
                HIGHLIGHTEDARTICLES.toggleArticleHighlighted(article.id);
                let highlightData = {};
                highlightData[article.id] = FireDelete();
                ModelManager.update({useSet: true, stopEvents: true, resource: "/client/" + AuthStorage.getClientId() + "/highlightedArticles/", id: '_all', customData: highlightData});
            }

            article.content.forEach(content => {
                try {
                    if(content.type === ContentType.PDF && !!content.path) {
                        deleteFutures.push(firebaseStorageManager.deleteFile(content.path.replace(/%2F/g, '/')));
                    } else if(content.type === ContentType.MEDIA) {
                        if(!!content.image) {
                            deleteFutures.push(firebaseStorageManager.deleteFile(content.image.replace(/%2F/g, '/')));
                        } else if(!!content.video) {
                            deleteFutures.push(firebaseStorageManager.deleteFile(content.video.url.replace(/%2F/g, '/')));
                            deleteFutures.push(firebaseStorageManager.deleteFile(content.video.thumbnail.replace(/%2F/g, '/')));
                        }
                    }
                } catch (ex) {
                    //if we have a faulty file it might not be possible to delete it, but we should delete the article anyways
                }
            });

            let customArticleDeleteData = {
                content: FireDelete(),
                title: FireDelete(),
                uAt: FireTimestamp(),
                dAt: FireTimestamp(),
                dBy: authUser.id,
                hTI: FireDelete(),
                newFormat: FireDelete(),
                dis: FireDelete()
            };

            article.uAt = new Date();
            article.dAt = new Date();
            article.dBy = authUser.id;
            article.content = undefined;
            article.title = undefined;
            article.hTI = undefined;
            article.newFormat = undefined;
            article.dis = undefined;

            await ModelManager.update({modelInstance: article, customData: customArticleDeleteData, resource: `/client/${AUTHSTORAGE.getClientId()}/article`});
            await Promise.all(deleteFutures);
            deleting.current = false;
            history.goBack();
            return true;
        }
        return false;
    };

    return (
        <ArticleDetail
            article={article}
            comments={relevantComments}
            group={group}
            loadMoreComments={loadMoreComments}
            loadingComments={ commentsLoading.current}
            deleting={deleting.current}
            deleteArticle={deleteArticle}
            toggleArticleHighlight={toggleArticleHighlight}
            toggleArticleFavorited={toggleArticleFavorited}
        />
    );
};

const mapState = (state, ownProps) => {
    const article = getModelInstance(state, Article, ownProps.id);
    let group;

    if(!!article && !!article.dis) {
        group = getModelInstance(state, Distributor, article.dis[0]);
    }

    return {
        article: article,
        group: group,
        surveys: getModelInstances(state, Survey),
        comments: getModelInstances(state, Comment),
        authUser: getModelInstance(state, User, AUTHSTORAGE.getUserId())
    };
};

const mapAction = {
    addModelInstanceDispatch,
    deleteModelInstanceDispatch,
    updateModelInstanceDispatch
};

export default connect(mapState, mapAction)(withRouter(ArticleDetailContainer));
